import * as React from 'react'
import Button from 'components/buttons/button'
import ContentfulRichText from 'components/rich-text'
import TickMarkWideIcon from 'icons/tick-mark-wide'
import Modal from 'components/modal/modal'
import CrossIcon from 'icons/CrossIcon'
import { colors } from 'theme.cjs'
import styles from './style.module.css'
import { IPageSectionForm } from 'interfaces/page-section-form'
import { userSubscribeEventName } from 'misc-variables'
import { useCustomerContext } from 'context/customer-context'

interface IProps {
    form: IPageSectionForm
    // page/section where form is embedded
    formLocation?: string
    renderType?: IPageSectionForm['renderType']
}

const GenericForm = ({ form, formLocation, renderType }: IProps): React.ReactElement => {
    const { action: endpoint, inputsCollection, submitButtonText, successMessage, errorMessage } = form ?? {}

    const inputs = inputsCollection?.items

    const [isSubmittingForm, setIsSubmittingForm] = React.useState(false)

    const [formSubmissionSuccess, setFormSubmissionSuccess] = React.useState(null)
    const [formSubmissionError, setFormSubmissionError] = React.useState(null)

    const formElRef = React.useRef<HTMLFormElement>()

    const { track } = useCustomerContext()

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        try {
            const formData = new FormData(formElRef.current)

            const formEntries = formData?.entries()

            const formObj = Object.fromEntries(formEntries)

            setIsSubmittingForm(true)

            if (endpoint === '/api/subscribe') {
                formObj['subscribeLocation'] = formLocation
            }

            const response = await fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify(formObj),
            })
            if (response.status === 200) {
                setFormSubmissionSuccess(true)

                if (endpoint === '/api/subscribe') {
                    track(`${formLocation} - ${userSubscribeEventName} - Embedded Form`, {
                        subscribeLocation: formLocation,
                    })
                }
            } else {
                setFormSubmissionError(true)
            }
        } catch (e) {
            setFormSubmissionError(e)
        } finally {
            setIsSubmittingForm(false)
        }
    }

    return (
        <>
            <form
                ref={formElRef}
                onSubmit={handleFormSubmit}
                className={`flex ${inputs?.length > 1 ? 'flex-col' : ''}`}
            >
                {inputs?.map(({ name, placeholder, sys }) => (
                    <input
                        name={name}
                        placeholder={placeholder}
                        key={sys?.id}
                        className={` p-2.5 grow ${
                            renderType === 'Fixed inputs' ? 'sm:grow-0' : ''
                        } border border-gray text-xbase ${
                            inputs.length === 1
                                ? `ring-inset pl-6 py-2.5 pr-3 w-1/2 sm:w-auto rounded-l-full border-r-0 ${styles['small-input']}`
                                : 'rounded-full mt-2.5'
                        }`}
                    />
                ))}
                <Button
                    loading={isSubmittingForm}
                    className={`shrink-0 text-xbase p-2.5 ${
                        inputs?.length === 1
                            ? `ring-inset pr-6 pl-3.5 py-2.5 rounded-tl-none rounded-bl-none ${styles['button']}`
                            : 'rounded-full mt-2.5'
                    }`}
                >
                    {submitButtonText}
                </Button>
            </form>
            {(formSubmissionSuccess || formSubmissionError) && (
                <Modal
                    closeModal={() => {
                        setFormSubmissionSuccess(null)
                        setFormSubmissionError(null)
                    }}
                >
                    <div className={`p-4 flex items-center ${styles['modal-content']}`}>
                        <div className="flex">
                            <div
                                className={`h-9 w-9 rounded-full mr-2.5 flex items-center justify-center shrink-0 ${
                                    formSubmissionSuccess ? 'bg-success' : 'bg-error'
                                }`}
                            >
                                {formSubmissionSuccess ? (
                                    <TickMarkWideIcon stroke={colors.white} />
                                ) : (
                                    <CrossIcon stroke={colors.white} />
                                )}
                            </div>
                            <span>
                                {formSubmissionSuccess ? (
                                    <ContentfulRichText content={successMessage?.json} />
                                ) : (
                                    <ContentfulRichText content={errorMessage?.json} />
                                )}
                            </span>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default GenericForm
