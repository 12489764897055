import * as React from 'react'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import { IPicture } from 'interfaces/picture'
import Link from 'next/link'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import { IMedia } from 'interfaces/media'
import Video from 'components/video/video'
import { IUiResource, IUiResources } from 'interfaces/ui-resource'

interface IProps {
    section: IPageSectionVisualizer
    uiResources: IUiResources
}

interface IGridRowProps {
    visual: IPicture
    isFirstRow?: boolean
    playMediaLabel: IUiResource
}

const descriptionRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-1xl lg:text-3.25xl font-medium tracking-tight leading-140% lg:leading-120% first:mt-0 lg:mt-20">
            {children}
        </p>
    ),
}

const lgBreakPoint = screens.lg

const Media = ({
    mobileMedia,
    desktopMedia,
    description,
    playMediaLabel,
}: {
    mobileMedia: IMedia
    desktopMedia: IMedia
    description: string
    playMediaLabel: IUiResource
}): React.ReactElement => {
    const isMobileScreen = useMobileScreenDetection()

    if (!mobileMedia && !desktopMedia) {
        return null
    }

    const mobileVideo = mobileMedia?.contentType?.includes('video') ? mobileMedia : null
    const desktopVideo = desktopMedia?.contentType?.includes('video') ? desktopMedia : null

    if (mobileVideo && isMobileScreen) {
        return (
            <Video
                className="h-full w-full object-cover rounded-2lg"
                autoPlay
                loop
                playsInline
                muted
                playMediaLabel={playMediaLabel}
            >
                <source src={mobileVideo.url} type={mobileVideo.contentType} />
            </Video>
        )
    }

    if (desktopVideo) {
        return (
            <Video
                className="h-full w-full object-cover rounded-2lg"
                autoPlay
                loop
                playsInline
                muted
                playMediaLabel={playMediaLabel}
            >
                <source src={desktopVideo.url} type={desktopVideo.contentType} />
            </Video>
        )
    }

    const mobileImage = mobileMedia
    const desktopImage = mobileMedia

    return (
        <picture>
            {desktopImage?.url && (
                <>
                    <source
                        type="image/webp"
                        media={`(min-width: ${lgBreakPoint})`}
                        srcSet={`
                                            ${desktopImage.url}?fm=webp&w=800 800w,
                                            ${desktopImage.url}?fm=webp&w=1600 1600w
                                        `}
                    />
                    <source
                        type="image/jpeg"
                        media={`(min-width: ${lgBreakPoint})`}
                        srcSet={`
                                            ${desktopImage.url}?fm=jpg&w=800 800w,
                                            ${desktopImage.url}?fm=jpg&w=1600 1600w
                                        `}
                    />
                </>
            )}

            {mobileImage?.url && (
                <>
                    <source
                        type="image/webp"
                        srcSet={`
                                            ${mobileImage.url}?fm=webp&w=800 800w,
                                            ${mobileImage.url}?fm=webp&w=1600 1600w
                                        `}
                    />
                    <source
                        type="image/jpeg"
                        srcSet={`
                                            ${mobileImage.url}?fm=jpg&w=800 800w,
                                            ${mobileImage.url}?fm=jpg&w=1600 1600w
                                        `}
                    />
                </>
            )}

            <img
                src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=800'}
                height={mobileImage.height || desktopImage.height}
                width={mobileImage.width || desktopImage.width}
                alt={description}
                loading="lazy"
                className="w-full h-full object-cover rounded-2lg"
            />
        </picture>
    )
}

const GridRow = ({ visual, isFirstRow, playMediaLabel }: IGridRowProps) => {
    const [textColumnEl, setTextColumnEl] = React.useState<HTMLDivElement>()
    const [mediaContainerEl, setMediaContainerEl] = React.useState<HTMLDivElement>()

    const textColumnRef = React.useCallback((node) => {
        if (node !== null) {
            setTextColumnEl(node)
        }
    }, [])

    const mediaContainerRef = React.useCallback((node) => {
        if (node !== null) {
            setMediaContainerEl(node)
        }
    }, [])

    const [intersectionObserverOptions, setIntersectionObserverOptions] = React.useState<IntersectionObserverInit>({
        rootMargin: '-25% 0px',
    })

    React.useEffect(() => {
        const setupIntersectionObserverOptions = () => {
            if (mediaContainerEl) {
                // set margin lines parallel to media container
                const rootMarginVertical =
                    (window.innerHeight / 2 - mediaContainerEl?.clientHeight / 2) * -1 + 50 + 'px'
                const rootMargin = `${rootMarginVertical} 0px`

                setIntersectionObserverOptions({ rootMargin })
            }
        }

        // initial setup
        setupIntersectionObserverOptions()

        let timeout

        const resizeHandler = () => {
            clearTimeout(timeout)

            timeout = setTimeout(() => {
                setupIntersectionObserverOptions()
            }, 1000)
        }

        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [mediaContainerEl])

    const isMobileScreen = useMobileScreenDetection()
    const { entry, observer } = useIntersectionObserver({ element: textColumnEl, options: intersectionObserverOptions })

    React.useEffect(() => {
        if (isMobileScreen) {
            observer?.disconnect()
        }
    }, [isMobileScreen, observer])

    const isTextElIntersecting = entry?.isIntersecting
    const isTextElBelowViewPort = entry?.boundingClientRect?.top > 0

    const { richDescription, desktopImagesCollection, mobileImagesCollection, cta, description } = visual
    const mobileMedia = mobileImagesCollection.items[0]
    const desktopMedia = desktopImagesCollection.items[0]

    return (
        <>
            <div ref={textColumnRef} className="mt-6 lg:mt-0 lg:col-start-1 lg:col-end-2">
                <ContentfulRichText
                    content={richDescription.json}
                    renderNode={descriptionRenderNode}
                    ignoreMarkdownStyles
                    className={`lg:max-w-[40ch] ${isTextElIntersecting ? '' : 'lg:opacity-50'} transition-colors`}
                />

                {cta?.href && (
                    <Link href={cta.href} className="hidden lg:inline-block button button-primary mt-12 px-11">
                        {cta.name}
                    </Link>
                )}
            </div>

            <div
                className={`lg:sticky top-[calc(50%-var(--renderedHeight)/2+var(--secondaryNavbarHeight))] lg:col-start-2 lg:col-end-3 lg:row-start-1 lg:row-end-2 h-full ${
                    isTextElIntersecting || (isTextElBelowViewPort && isFirstRow) ? '' : 'lg:opacity-0'
                } transition-opacity`}
                style={{
                    ['--renderedHeight' as string]: mediaContainerEl?.clientHeight + 'px',
                }}
                ref={mediaContainerRef}
            >
                <Media
                    mobileMedia={mobileMedia}
                    desktopMedia={desktopMedia}
                    description={description}
                    playMediaLabel={playMediaLabel}
                />
            </div>

            {cta?.href && (
                <Link href={cta.href} className="inlin-block lg:hidden button button-primary px-11">
                    {cta.name}
                </Link>
            )}
        </>
    )
}

export const VisualizerStackedWithStickyAnimationSection = ({ section, uiResources }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { visualsCollection } = section
    const visuals = visualsCollection.items

    return (
        <section className="section--v2">
            <div className="grid lg:grid-cols-2 lg:auto-rows-fr gap-y-8 lg:gap-7 items-center max-w-7xl mx-auto">
                {visuals.map((visual, index) => (
                    <GridRow
                        key={visual.sys.id}
                        visual={visual}
                        isFirstRow={index === 0}
                        playMediaLabel={uiResources['playMediaLabel']}
                    />
                ))}
            </div>
        </section>
    )
}
