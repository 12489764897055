/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionForm } from 'interfaces/page-section-form'
import RxSearchForm from 'components/rx-search-form'
import { IUiResources } from 'interfaces/ui-resource'
import { ICommonCopyDict } from 'interfaces/common-copy'

interface IProps {
    section: IPageSectionForm
    uiResources: IUiResources
    commonCopy: ICommonCopyDict
    className?: string
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <div className="section--header font-medium leading-110% tracking-tighter">{children}</div>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="section--subheader lg:text-lg font-medium leading-140% tracking-tight">{children}</p>
    ),
}

export const SearchFormSection = ({ section, uiResources, className = '', commonCopy }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, subHeader, inputsCollection, alignImage: alignment, backgroundColor } = section

    const searchInput = inputsCollection.items[0]

    // we have different spacing for sections with background
    const useSmallMarginSpacing = backgroundColor && backgroundColor !== 'transparent'

    return (
        <section
            className={`section--v2 ${useSmallMarginSpacing ? 'section--small-margin-block' : ''}  ${className}`}
            style={{ backgroundColor }}
        >
            <div className={`max-w-2xl ${alignment === 'center' ? 'mx-auto' : alignment === 'right' ? 'ml-auto' : ''}`}>
                {header?.json && (
                    <h2>
                        <ContentfulRichText
                            className={`${
                                alignment === 'center' ? 'text-center' : alignment === 'right' ? 'text-right' : ''
                            }`}
                            content={header.json}
                            renderNode={headerRenderNode}
                        />
                    </h2>
                )}

                {subHeader?.json && (
                    <div className="mt-6 lg:mt-8">
                        <ContentfulRichText
                            className={
                                alignment === 'center' ? 'text-center' : alignment === 'right' ? 'text-right' : ''
                            }
                            content={subHeader.json}
                            renderNode={subHeaderRenderNode}
                        />
                    </div>
                )}

                <div
                    className={`mt-8 relative min-h-[50px] max-w-lg ${
                        alignment === 'center' ? 'mx-auto' : alignment === 'right' ? 'ml-auto' : ''
                    }`}
                >
                    <div className="absolute w-full z-20">
                        <RxSearchForm uiResources={uiResources} commonCopy={commonCopy} searchInput={searchInput} />
                    </div>
                </div>
            </div>
        </section>
    )
}
