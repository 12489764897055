/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import Link from 'next/link'
import styles from './style.module.css'
import Video from 'components/video/video'
import globalManifest from 'data/global-manifest.json'
import { getContentfulTransparentBackgroundImageQuery } from 'lib/util/image'
import { IUiResource } from 'interfaces/ui-resource'

interface IProps {
    section: IPageSectionVisualizer
    playMediaLabel: IUiResource
}

const contentfulImageBGQuery = getContentfulTransparentBackgroundImageQuery()

const { resources: globalUIResources } = globalManifest ?? {}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="section--header-big">{children}</p>,
}

const descriptionRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-xbase lg:text-base leading-140%">{children}</p>,
}

const mdBreakPoint = screens.md
const lgBreakPoint = screens.lg

export const VisualizerTimelineSection = ({ section, playMediaLabel }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }
    const { richTitle, visualsCollection, backgroundColor } = section

    return (
        <section className="section section--bigger-padding flex justify-center" style={{ backgroundColor }}>
            <div className="section--container">
                {/* header */}
                {richTitle?.json && (
                    <h2 className="md:text-center">
                        <ContentfulRichText content={richTitle.json} renderNode={headerRenderNode} />
                    </h2>
                )}

                {/* visuals */}
                {visualsCollection?.items?.length > 0 && (
                    <div className="mt-12.5 lg:mt-17.5 grid gap-17.5 md:gap-32 lg:gap-64 relative">
                        {visualsCollection.items.map((visual) => {
                            if (!visual) {
                                return null
                            }

                            const {
                                sys,
                                mobileImagesCollection,
                                desktopImagesCollection,
                                title,
                                richDescription,
                                cta,
                            } = visual

                            const mobileImage = mobileImagesCollection?.items?.[0]
                            const desktopImage = desktopImagesCollection?.items?.[0]

                            return (
                                <div key={sys?.id} className="grid md:grid-cols-2 md:gap-32 ml-4 items-center">
                                    {mobileImage?.contentType?.includes('video') ||
                                    desktopImage?.contentType?.includes('video') ? (
                                        <Video
                                            autoPlay
                                            playsInline
                                            loop
                                            muted
                                            width="100%"
                                            playMediaLabel={playMediaLabel}
                                        >
                                            <source
                                                src={mobileImage.url || desktopImage.url}
                                                type={mobileImage.contentType}
                                            />
                                            <span>
                                                {globalUIResources?.['browserDoesNotSupportVideoMessage']?.value}
                                            </span>
                                        </Video>
                                    ) : (
                                        <>
                                            {(mobileImage || desktopImage) && (
                                                <picture>
                                                    {desktopImage && (
                                                        <>
                                                            {/* desktop webp */}
                                                            <source
                                                                type="image/webp"
                                                                media={`(min-width: ${mdBreakPoint})`}
                                                                srcSet={`
                                                ${desktopImage.url}?fm=webp&w=420 420w,
                                                ${desktopImage.url}?fm=webp&w=640 640w,
                                                ${desktopImage.url}?fm=webp&w=840 840w
                                            `}
                                                                sizes={`(min-width: ${lgBreakPoint}) 40vw, 50vw`}
                                                            />

                                                            {/* desktop jpeg */}
                                                            <source
                                                                type="image/jpeg"
                                                                media={`(min-width: ${mdBreakPoint})`}
                                                                srcSet={`
                                                ${desktopImage.url}?fm=jpg&${contentfulImageBGQuery}&w=420 420w,
                                                ${desktopImage.url}?fm=jpg&${contentfulImageBGQuery}&w=640 640w,
                                                ${desktopImage.url}?fm=jpg&${contentfulImageBGQuery}&w=840 840w
                                            `}
                                                                sizes={`(min-width: ${lgBreakPoint}) 40vw, 50vw`}
                                                            />
                                                        </>
                                                    )}

                                                    {mobileImage && (
                                                        <>
                                                            {/* mobile webp */}
                                                            <source
                                                                type="image/webp"
                                                                srcSet={`
                                                ${mobileImage.url}?fm=webp&w=360 360w,
                                                ${mobileImage.url}?fm=webp&w=420 420w,
                                                ${mobileImage.url}?fm=webp&w=640 640w
                                            `}
                                                            />

                                                            {/* mobile jpeg */}
                                                            <source
                                                                type="image/jpeg"
                                                                srcSet={`
                                                ${mobileImage.url}?fm=jpg&${contentfulImageBGQuery}&w=360 360w,
                                                ${mobileImage.url}?fm=jpg&${contentfulImageBGQuery}&w=420 420w,
                                                ${mobileImage.url}?fm=jpg&${contentfulImageBGQuery}&w=640 640w
                                            `}
                                                            />
                                                        </>
                                                    )}

                                                    <img
                                                        src={(mobileImage.url || desktopImage.url) + '?fm=jpg&w=840'}
                                                        alt={
                                                            mobileImage.title ||
                                                            mobileImage.description ||
                                                            desktopImage.title ||
                                                            desktopImage.description
                                                        }
                                                        height={mobileImage.height || desktopImage.height}
                                                        width={mobileImage.width || desktopImage.width}
                                                        loading="lazy"
                                                    />
                                                </picture>
                                            )}
                                        </>
                                    )}

                                    {/* contents */}
                                    <div>
                                        {/* title */}
                                        {title && (
                                            <p className="mt-7.5 lg:mt-0 text-1xl lg:text-3.25xl leading-120%">
                                                {title}
                                            </p>
                                        )}

                                        {/* description */}
                                        {richDescription?.json && (
                                            <ContentfulRichText
                                                className="mt-7.5 lg:mt-10"
                                                content={richDescription.json}
                                                renderNode={descriptionRenderNode}
                                            />
                                        )}

                                        {/* cta */}
                                        {cta?.href && (
                                            <Link
                                                href={cta.href}
                                                className="inline-block mt-7.5 lg:mt-10 text-gray text-xsm lg:text-sm tracking-wide underline"
                                            >
                                                {cta.name}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            )
                        })}

                        {/* sliding dot */}
                        <div className={styles['dot-slider']}>
                            {/* dot */}
                            <div className={styles['dot']} />
                            {/* track */}
                            <div className={styles['dot-track']} />
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}
